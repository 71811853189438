<template>
  <div class="view" v-if="$lang === 'hr'">
    <div class="container">
      <div class="container small-container text-center">
        <h2 class="fw-200 mt-0 pt-3">{{ t.h1 }}</h2>
        <div class="text-center mb-3 mt-4">
          <img style="width: 20px" src="/static/images/svg/LibertyStatue_8e8362.svg" alt="">
        </div>
        <div>
          <p>{{ t.p1 }}</p>
          <p>{{ t.p2 }}</p>
        </div>
      </div>
      <div class="row mt-5 books_cont">
        <div class="col-sm-12 col-md-6">
          <div class="book_wrapper">
            <router-link :to="{name: 'book_I'}">
              <img @click="()=>openBook(1)" src="/static/images/KnjigaPartI.png" alt="The Croatian Diaspora Book">
            </router-link>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="book_wrapper">
            <router-link :to="{name: 'book_II'}">
              <img @click="()=>openBook(2)" src="/static/images/KnjigaPartII.png" alt="The Croatian Diaspora Book">
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

const t =
//<i18n>
    {
      "en": {
        h1: 'Find your ancestor. Learn their story.',
        p1: 'Look into more than 30 thousand emigration cards from the Croatian State ' +
            'Archives and immerse yourself into profound emigrant stories.',
        p2: 'Please choose between Part I and Part II depending on the alphabetical ' +
            'order of transcribed emigrant cards.'
      },
      "hr": {
        h1: 'Pronađite svoje pretke. Saznajte njihovu priču.',
        p1: 'Zavirite u više od 30 tisuća iseljeničkih kartica iz Hrvatskog državnog ' +
            'arhiva i uronite u duboke iseljeničke priče.',
        p2: 'Molimo odaberite između I. i II. dijela, ovisno o abecednom redu prepisanih iseljeničkih karata.'
      }
    }
//</i18n>

export default {
  name: "BookView",
  components: {},
  data() {
    return {
      t: t[this.$lang]
    }
  },
  methods: {
    openBook(n) {

    }
  }
}
</script>

<style scoped lang="scss">

.view {
  padding: 0 0 40px;
  background-color: #fdffec;
  color: #8e8362;
}

.small-container {
  max-width: 760px!important;  // to fit croatian h1
}

.books_cont {
  max-width: 580px;
  margin: 0 auto;
  text-align: center;


  & > div {
    margin: 0 auto;
    @media(max-width: 768px) {
      margin-bottom: 30px;
    }
  }

  a {
    display: block;
  }

  .book_wrapper {
    display: inline-block;
    padding: 5px 5px 5px;
    margin: 0 auto;
    width: 220px;
    max-width: 90%;
    transition: .3s;
  }

  .book_wrapper:hover {
    background-color: rgb(195, 15, 32);
  }

  img {
    width: 100%;
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    vertical-align: auto;
  }
}

</style>